import { render, staticRenderFns } from "./zwsq.vue?vue&type=template&id=024c998a&scoped=true&"
import script from "./zwsq.vue?vue&type=script&lang=js&"
export * from "./zwsq.vue?vue&type=script&lang=js&"
import style0 from "./zwsq.vue?vue&type=style&index=0&id=024c998a&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "024c998a",
  null
  
)

export default component.exports