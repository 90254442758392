<template>
    <div class="zwsqtemplate"  >
        <section style="margin-left: calc((100% - 1200px) / 2); margin-top: 90px;">


            <div class="htable">

                <div class="hcell" style="width: 880px;">

                    <div style=" height: 75px;background-color: #FFFFFF;font-size: 14px;line-height: 80px;">
                        <span style="padding-left: 30px;" >职位类别：</span><span style="margin-left: 10px;color: #d7000f">全部</span>
                    </div>
                    <div style="background-color: #FFFFFF;font-size: 14px;line-height: 30px;margin-top: 30px;">
                        <div v-if="drows[0]" style="padding : 20px 30px; "  >
                            <div @click="setShowContent(0);" style="cursor:pointer;">
                                <div class="htable" style="align-items: center;justify-content: space-between;">
                                    <div class="hcell"  >
                                        <span v-if="drows[0].tb_description=='急'" style="color: #FFFFFF;background-color:  #d7000f;padding: 2px;" >急</span>
                                        <span v-if="drows[0].tb_description=='急'" style="padding-left: 12px;"></span><span style="color:rgba(32,32,32,1);">{{drows[0].tb_title}}</span>
                                    </div>
                                    <div class="hcell" style="color:rgba(32,32,32,0.7);">发布时间：{{drows[0].tb_create_time |mmt('YYYY-MM-DD') }}</div>
                                </div>
                                <div style="color:rgba(32,32,32,0.7);"> {{drows[0].tb_title_sub}} <span style="padding: 0 10px;">|</span> 招聘人数：{{drows[0].tb_keywords}}</div>
                                <div style="color:rgba(32,32,32,0.7);">工作地点： {{drows[0].tb_title_seo}}</div>
                            </div>
                            <div v-if="drows[0].show_content===true">
                                <div  style="width: 100%;border-bottom: 1px dashed rgba(32,32,32,0.3);margin-top: 20px;"></div>
                                <div v-html="drows[0].tb_content"> </div>
                                <div style="margin-bottom: 60px;"> <span class="pointer" style="cursor:pointer;color: #FFFFFF;background-color:  #d7000f;padding: 12px 23px;font-size: 14px;line-height: 36px;" @click="openZwsq" >职位申请</span></div>
                            </div>
                        </div>
                        <div v-else> 无招聘信息</div>
                        <div v-if="drows[1]">
                        <div  v-for="(n,i) of Math.ceil(drows.length-1)" :key="'item'+drows[i+1].tb_id" >
                            <div class="htable">
                                <div class="hcell" style="width: 30px;"></div>
                                <div class="hcell" style="width: 100%;border-bottom: 1px dashed rgba(32,32,32,0.3);"></div>
                                <div class="hcell" style="width: 30px;"></div>
                            </div>
                            <div style="padding : 20px 30px; " >
                                <div @click="setShowContent(i+1)" style="cursor:pointer;">
                                    <div class="htable" style="align-items: center;justify-content: space-between;">
                                        <div class="hcell" >
                                            <span v-if="drows[i+1].tb_description=='急'" style="color: #FFFFFF;background-color:  #d7000f;padding: 2px;" >急</span>
                                            <span v-if="drows[i+1].tb_description=='急'" style="padding-left: 12px;"></span><span style="color:rgba(32,32,32,1);">{{drows[i+1].tb_title}}</span></div>
                                        <div class="hcell" style="color:rgba(32,32,32,0.7);">发布时间：{{drows[i+1].tb_create_time |mmt('YYYY-MM-DD') }}</div>
                                    </div>
                                    <div style="color:rgba(32,32,32,0.7);"> {{drows[i+1].tb_title_sub}} <span style="padding: 0 10px;">|</span> 招聘人数：{{drows[i+1].tb_keywords}}</div>
                                    <div style="color:rgba(32,32,32,0.7);">工作地点： {{drows[i+1].tb_title_seo}}</div>
                                </div>
                                <div v-if="drows[i+1].show_content===true" >
                                    <div  style="width: 100%;border-bottom: 1px dashed rgba(32,32,32,0.3);margin-top: 20px;"></div>
                                    <div v-html="drows[i+1].tb_content"> </div>
                                    <div style="margin-bottom: 60px;"> <span class="pointer" style="cursor:pointer;color: #FFFFFF;background-color:  #d7000f;padding: 12px 23px;font-size: 14px;line-height: 36px;" @click="openZwsq" >职位申请</span></div>
                                </div>
                            </div>
                        </div>
                        </div>
                         <pages :page="param.page" :size="param.rows" :total="total" @go="goPage" style="margin-top: 50px;"></pages>
                    </div>
                </div>
               <div class="hcell" style="width: 290px;margin-left: 30px;font-size: 14px;line-height: 30px;">
                    <div class="htable" style="padding : 0px 30px;background-color: #FFFFFF;align-items: center;justify-content: space-between;">
                        <div class="hcell" style="font-size: 16px;line-height: 80px;">最新职位</div>
                        <div class="hcell" style="font-size: 14px;line-height: 80px;color:rgba(32,32,32,0.5);"> </div>
                    </div>
                    <div v-if="drows[0]" style="padding : 0px 30px 15px 30px; background-color: #FFFFFF;cursor:pointer;" @click="setShowContent(0);"  >
                        <span v-if="drows[0].tb_description=='急'" style="color: #FFFFFF;background-color:  #d7000f;padding: 2px;" >急</span>
                        <span v-if="drows[0].tb_description=='急'" style="padding-left: 12px;"></span><span style="color:rgba(32,32,32,1);">{{drows[0].tb_title}}</span>
                    </div>
                   <div v-if="drows[1]">
                       <div class="htable">
                           <div class="hcell" style="width: 30px;"></div>
                           <div class="hcell" style="width: 100%;border-bottom: 1px dashed rgba(32,32,32,0.3);"></div>
                           <div class="hcell" style="width: 30px;"></div>
                       </div>
                       <div style="padding : 15px 30px; background-color: #FFFFFF;cursor:pointer;" @click="setShowContent(1);"  >
                           <span v-if="drows[1].tb_description=='急'" style="color: #FFFFFF;background-color:  #d7000f;padding: 2px;" >急</span>
                           <span v-if="drows[1].tb_description=='急'" style="padding-left: 12px;"></span><span style="color:rgba(32,32,32,1);">{{drows[1].tb_title}}</span>
                       </div>
                   </div>
                   <div v-if="drows[2]">
                       <div class="htable">
                           <div class="hcell" style="width: 30px;"></div>
                           <div class="hcell" style="width: 100%;border-bottom: 1px dashed rgba(32,32,32,0.3);"></div>
                           <div class="hcell" style="width: 30px;"></div>
                       </div>
                       <div style="padding : 15px 30px; background-color: #FFFFFF;cursor:pointer;" @click="setShowContent(2);"  >
                           <span v-if="drows[2].tb_description=='急'" style="color: #FFFFFF;background-color:  #d7000f;padding: 2px;" >急</span>
                           <span v-if="drows[2].tb_description=='急'" style="padding-left: 12px;"></span><span style="color:rgba(32,32,32,1);">{{drows[2].tb_title}}</span>
                       </div>
                   </div>

                   <div v-if="drows[3]">
                       <div class="htable">
                           <div class="hcell" style="width: 30px;"></div>
                           <div class="hcell" style="width: 100%;border-bottom: 1px dashed rgba(32,32,32,0.3);"></div>
                           <div class="hcell" style="width: 30px;"></div>
                       </div>
                       <div style="padding : 15px 30px; background-color: #FFFFFF;cursor:pointer;" @click="setShowContent(3);"  >
                           <span v-if="drows[3].tb_description=='急'" style="color: #FFFFFF;background-color:  #d7000f;padding: 2px;" >急</span>
                           <span v-if="drows[3].tb_description=='急'" style="padding-left: 12px;"></span><span style="color:rgba(32,32,32,1);">{{drows[3].tb_title}}</span>
                       </div>
                   </div>
                   <div v-if="drows[4]">
                       <div class="htable">
                           <div class="hcell" style="width: 30px;"></div>
                           <div class="hcell" style="width: 100%;border-bottom: 1px dashed rgba(32,32,32,0.3);"></div>
                           <div class="hcell" style="width: 30px;"></div>
                       </div>
                       <div style="padding :15px 30px; background-color: #FFFFFF;cursor:pointer;" @click="setShowContent(4);"  >
                           <span v-if="drows[4].tb_description=='急'" style="color: #FFFFFF;background-color:  #d7000f;padding: 2px;" >急</span>
                           <span v-if="drows[4].tb_description=='急'" style="padding-left: 12px;"></span><span style="color:rgba(32,32,32,1);">{{drows[4].tb_title}}</span>
                       </div>
                   </div>
                   <!--<div  v-if="drows[1]">
                        <div v-for="(n,i) of Math.ceil((drows.length-1))" :key="'item'+drows[i+1].tb_id" style="background-color: #FFFFFF;" >
                            <div class="htable">
                                <div class="hcell" style="width: 30px;"></div>
                                <div class="hcell" style="width: 100%;border-bottom: 1px dashed rgba(32,32,32,0.3);"></div>
                                <div class="hcell" style="width: 30px;"></div>
                            </div>
                            <div style="padding : 20px 30px; cursor:pointer;" @click="setShowContent(0);"  >
                                <span v-if="drows[i+1].tb_description=='急'" style="color: #FFFFFF;background-color:  #d7000f;padding: 2px;" >急</span>
                                <span v-if="drows[i+1].tb_description=='急'" style="padding-left: 12px;"></span><span  style="color:rgba(32,32,32,1);">{{drows[i+1].tb_title}}</span>
                            </div>
                        </div>
                   </div>-->
                </div>
            </div>
        </section>

    </div>

</template>
<script>
    import { listTopicBase } from "../hts/api.js"
    import pages from '../components/pagesc.vue'
    const param = {
        pageindex: 5,
        rows: 7,
        page: 1,
    }
    export default {
        data () {
            return {
                page:1,
                param,
                total: 0,
                drows:[],
            }
        },
        components: {
            pages,
        },
        mounted() {
            this.getList()
        },
        methods: {
            setShowContent(i){
                if (this.drows[i]){
                    if (this.drows[i].show_content){
                        this.drows[i].show_content = !this.drows[i].show_content;
                    }else{
                        this.drows[i].show_content=true;
                    }
                }
            },
            goPage (page) {
                this.param.page = page
                this.getList()
            },
            getList(){
                listTopicBase({fbId: '100', page: this.param.page, rows: this.param.rows })
                    .then(resData => {
                        var drows=resData.data.rows
                        this.total=resData.data.total
                        drows.forEach((elem) => {
                            elem.show_content=false;
                        });
                        this.drows=drows
                    })
                    .catch()
            },
            openZwsq(){
                this.$parent.openZwsq()
            }

        },
        watch: {

        },
    }
</script>
<style lang="css" scoped>
    .zwsqtemplate{
        color: #202020;
        text-align: left;
    }
    .txt18{
        font-size: 18px;
        color:rgba(20,20,20,0.8);
        line-height: 32px;
    }
    .txt42{
        font-size: 42px;
        color:rgba(20,20,20,1);
        line-height: 42px;
    }
    .txt60{
        font-size: 60px;
        color:rgba(20,20,20,1);
        line-height: 60px;
    }

    .farm_ghblock{
        margin-top: 60px;
        /*height: 940px;*/
        /*border: 1px solid red;*/
    }
    .txtH16{
        font-size: 16px;
        color:rgba(20,20,20,0.8);
        line-height: 80px;
    }
    .hsc_fa1{
        background-image: url('~assets/hsc_jg1.jpg');
        width: 859px;
        height: 357px;
    }
    .hsc_fa2{
        background-image: url('~assets/hsc_jg2.jpg');
        height: 614px;
    }
    .ghblockclick{
        cursor:pointer;
    }
    .btLine{
        border-bottom:2px solid #d7000f;
        padding-bottom: 28px;
    }

    .block3{
        width: 374px;
        height: 270px;
        border-radius: 10px;
    }



    .hscgl_tdd{
        width: 320px;

        /*border: 1px solid red;*/
    }
    .hscgl_tddh{
        font-size: 18px;
        font-weight: bold;
        color: rgba(20,20,20,0.9);
        margin-top: 30px;
    }
    .hscgl_tddt{
        font-size: 16px;
        color: rgba(20,20,20,0.8);
        margin-top: 20px;
        line-height: 20px;
    }

</style>
